<template>
  <div class="todo-wrap fs-15">
    <div class="d-flex">
      <div class="flex-grow-1 pl-20 mt-10" style="border-right: 1px solid #eee">
        <p><strong>等待开始</strong></p>
        <ul>
          <li
            class="d-flex jc-between ai-center"
            v-for="(item, index) in todoList.start"
            :key="item"
          >
            <span>{{ item }}</span>
            <div>
              <button class="mr-5 m-c-p" @click="changeItem('start', index)">
                开始
              </button>
              <button class="m-c-p" @click="removeItem('start', index)">
                删除
              </button>
            </div>
          </li>
        </ul>
      </div>
      <div class="flex-grow-1 pl-20 mt-10" style="border-right: 1px solid #eee">
        <p><strong>正在进行</strong></p>
        <ul>
          <li
            class="d-flex jc-between mt-5 m-c-p"
            v-for="(item, index) in todoList.running"
            :key="item"
          >
            <span>{{ item }}</span>
            <div>
              <button class="mr-5 m-c-p" @click="changeItem('running', index)">
                完成
              </button>
              <button class="m-c-p" @click="removeItem('running', index)">
                删除
              </button>
            </div>
          </li>
        </ul>
      </div>
      <div class="flex-grow-1 pl-20 mt-10">
        <p><strong>已经完成</strong></p>
        <ul>
          <li
            class="d-flex jc-between mt-5 m-c-p"
            v-for="(item, index) in todoList.finished"
            :key="item"
          >
            <span>{{ item }}</span>
            <div>
              <button class="mr-5 m-c-p" @click="changeItem('finished', index)">
                等待
              </button>
              <button class="m-c-p" @click="removeItem('finished', index)">
                删除
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="control-wrap p-15 d-flex ai-center jc-center mt-20">
      <input
        ref="todoInput"
        type="text"
        v-model="content"
        placeholder="请输入新增内容"
        @keypress.enter="addItem"
        class="pl-10"
      />
      <button class="px-15 mx-20 m-c-p" @click="addItem">新增</button>
      <span class="text-border-grey"
        >注意：为确保云同步，使用该功能必须登录！</span
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: "",
      todoList: {
        start: [],
        running: [],
        finished: [],
      },
    };
  },
  methods: {
    //   从服务器拉取项目
    async pullItem() {
      const result = await this.$axios.get("/todolist");
      this.todoList = result.data.todoList;
    },
    //   修改服务器资源
    async itemServe() {
      const result = await this.$axios.post("/todolist", {
        todoList: this.todoList,
      });
      this.todoList = result.data.todoList;
    },
    //   添加项目
    addItem() {
      if (this.content && localStorage.token) {
        this.todoList.start.push(this.content);
        this.itemServe();
        this.content = "";
      }
    },
    //   删除项目
    removeItem(arr, index) {
      this.todoList[arr].splice(index, 1);
      this.itemServe();
    },
    //   改变项目状态
    changeItem(arr, index) {
      const item = this.todoList[arr].splice(index, 1)[0];
      if (arr == "start") {
        this.todoList.running.push(item);
      } else if (arr == "running") {
        this.todoList.finished.push(item);
      } else {
        this.todoList.start.push(item);
      }
      this.itemServe();
    },
  },
  mounted() {
    localStorage.token && this.pullItem();
    !localStorage.token && (this.$refs.todoInput.disabled = "disabled");
  },
  computed: {
    loginState() {
      return this.$store.state.LoginState;
    },
  },
  watch: {
    loginState(value) {
      if (!value) {
        this.todoList = {
          start: [],
          running: [],
          finished: [],
        };
        !localStorage.token && (this.$refs.todoInput.disabled = "disabled");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.todo-wrap {
  p {
    padding-bottom: 0.5rem;
    margin-right: 3rem;
    border-bottom: 1px solid #eee;
  }
}
ul {
  margin: 0 3rem 0 0.5rem;
  padding: 0;
  li {
    list-style-type: none;
    margin: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;
    span {
      width: 30rem;
    }
  }
}
.control-wrap {
  input {
    height: 3rem;
    width: 60rem;
  }
  button {
    height: 3rem;
    background: none;
    border: 1px solid #ccc;
    cursor: pointer;
  }
}
</style>