<template>
  <div class="search-wrap pt-30 d-flex ai-center fs-17">
    <div class="search-content d-flex flex-column">
      <!-- 搜索功能 -->
      <form class="ml-30 d-flex ai-center" :action="searchWord" target="_blank">
        <div class="choose-engine">
          <div class="engine-wrap">
            <span>{{ selected }}</span>
            <span class="iconfont icon-xiala pl-5  m-c-p" @click="showEngine"></span>
          </div>
          <div class="choose-button"></div>
        </div>
        <div class="search-input-wrap">
          <input
            class="search-input pl-15 fs-15"
            type="text"
            placeholder="请输入搜索内容"
            :name="keyword"
          />
        </div>
        <button type="submit" class="search-button pl-15 pr-20">
          <span class="iconfont icon-sousuo pr-5"></span>
          <span>搜索</span>
        </button>
      </form>
      <!-- 搜索引擎选择 -->
      <div class="xiala-engine" :class="{displaynone: displayNone}">
        <div class="pb-3 m-c-p" @click="chooseEngine('百度')">百度</div>
        <div class="pb-3 m-c-p" @click="chooseEngine('必应')">必应</div>
        <div class="pb-3 m-c-p" @click="chooseEngine('谷歌')">谷歌</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      displayNone: true,
      selected: "百度",
      baidu:'https://www.baidu.com/baidu',
      google:'https://www.google.com/search',
      bing:'https://www.bing.com/search'
    }
  },
  methods:{
    showEngine(){
      this.displayNone = !this.displayNone
    },
    chooseEngine(value){
      this.selected = value
      this.displayNone = true
      
    }
  },
  computed:{
    searchWord(){
      if(this.selected == '百度'){
          return this.baidu
        }else if(this.selected == '谷歌'){
          return this.google
        }else if(this.selected == '必应'){
          return this.bing
        }
    },
    keyword(){
      if(this.selected == '百度'){
          return 'word'
        }else if(this.selected == '谷歌'){
          return 'q'
        }else if(this.selected == '必应'){
          return 'q'
        }
    }
  },
  watch: {
    selected(value){
      this.$axios.post('/searchEngine', {searchEngine: value})
    }
  },
  mounted(){
    // 检验vuex中是否有账户存在, 若不存在，并且本地有token，发送获取用户信息请求
    if(localStorage.token && !this.$store.state.username){
      this.$axios.get('/user').then(res => {
        this.$store.commit('userInfo', [res.data, 'username'])
        this.selected = res.data.searchEngine
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/css/variables";
.search-wrap {
  height: 30rem;
  .search-content {
    height: 10rem;
    .choose-engine {
      width: 10rem;
      height: 4rem;
      line-height: 3.6rem;
      text-align: center;
      border: 1px solid map-get($colors, "border-grey");
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
      border-right: none;
    }
    .search-input-wrap {
      .search-input {
        width: 70rem;
        height: 4rem;
        border: 1px solid map-get($colors, "border-grey");
        border-left: none;
      }
    }
    .search-button {
      border: none;
      font-size: 1.7rem;
      text-align: center;
      height: 4rem;
      line-height: 3rem;
      display: inline-block;
      border-left: none;
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
      cursor: pointer;
      background-color: map-get($colors, "purple");
      color: white;
      .iconfont {
        font-size: 2.2rem;
        position: relative;
        top: 0.2rem;
      }
    }
    .xiala-engine{
      position: relative;
      line-height: 2.7rem;
      text-align: center;
      left: 4rem;
      top: 0.8rem;
      border-radius: 0.8rem;
      width: 6rem;
      border: 1px solid map-get($colors, 'border-light');
      &.displaynone{
        display: none;
      }
    }
    .xiala-engine::before{
      width: 0.8rem;
      height: 0.8rem;
      border: 1px solid map-get($colors, 'light');
      content: '';
      display: block;
      position: absolute;
      left: 2.5rem;
      transform: rotate(45deg);
      top: -0.5rem;
      border-right-color: transparent;
      border-bottom-color: transparent;
      background: white;
    }
  }
}
</style>