<template>
  <div class="body-scroll-wrap">
    <el-scrollbar>
      <div class="body-wrap">
        <!-- 首页搜索栏 -->
        <div class="home-swiper d-flex jc-center py-20">
          <index-search></index-search>
        </div>
        <!-- 导航栏 -->
        <div class="page-center">
          <button
            class="my-nav px-15 py-10 mt-15 m-c-p"
            @click="myNav = 'my-navi'"
            :class="{ active: myNav == 'my-navi' }"
          >
            我的常用导航
          </button>
          <button
            class="my-nav px-15 py-10 m-c-p"
            @click="myNav = 'todo-list'"
            :class="{ active: myNav == 'todo-list' }"
          >
            我的待办事项列表
          </button>
          <div class="my-nav-wrap pb-10">
            <component v-bind:is="myNav"></component>
          </div>
        </div>
        <!-- 介绍栏 -->
        <div class="page-center">
          <personal-menu></personal-menu>
        </div>
      </div>
      <footer-com></footer-com>
    </el-scrollbar>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import todoList from "../components/todoList.vue";
import myNavi from "../components/myNav.vue";
import "swiper/dist/css/swiper.css";
import IndexSearch from "../components/IndexSearch";
import personalMenu from "../components/PersonalMenu.vue";
import footerCom from "../components/Footer.vue";
export default {
  data() {
    return {
      myNav: "my-navi",
      swiperOption: {
        speed: 800,
        loop: true,
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false
        // },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  components: {
    swiper,
    swiperSlide,
    todoList,
    myNavi,
    IndexSearch,
    personalMenu,
    footerCom
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/variables";
.body-scroll-wrap {
  width: 100vw;
  margin-top: 5rem;
  height: calc(100vh - 35px);
  .el-scrollbar {
    height: 100%;
    .body-wrap {
      width: 140rem;
      margin-left: 45%;
      transform: translate(-68.7rem, 0);
      min-height: calc(100vh - 23rem);
      .home-swiper {
        .swiper-wrap {
          border-radius: 1.5rem;
          overflow: hidden;
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.18);
          border-top: 1px solid white;
        }
        .swiper-container {
          text-align: center;
          height: 57.6rem;
          img {
            width: 100%;
          }
          .swiper-button-prev {
            left: 5rem;
          }
          .swiper-button-next {
            right: 5rem;
          }
          .slide-first {
            position: relative;
            .text-wrap {
              position: absolute;
              color: white;
              font-size: 4rem;
              top: 16rem;
              right: 11rem;
              text-align: right;
              p {
                line-height: 3rem;
              }
              .zh-shixian {
                font-size: 3.5rem;
              }
            }
          }
        }
      }
    }
  }
}


.page-center {
  width: 140rem;
  .css-test {
    height: 180px;
    background: #5ea96e;
    width: 1000px;
    border-radius: 8px;
    overflow: hidden;
  }
}
.my-nav {
  border: 1px solid map-get($colors, "border-grey");
  background: none;
  border-right: none;
  position: relative;
  z-index: 2;
  &.active {
    border-bottom: 1px solid map-get($colors, "white");
    font-weight: bold;
  }
}
.my-nav:last-of-type {
  border-right: 1px solid map-get($colors, "border-grey");
}
.my-nav-wrap {
  position: relative;
  top: -1px;
  z-index: 1;
  border: 1px solid map-get($colors, "border-grey");
}
</style>