<template>
  <div>
    <ul class="d-flex fs-15 mx-30 flex-wrap">
      <li
        v-for="(item, index) in navList"
        :key="item.name"
        class="d-flex px-20 py-15 ai-center a-hover"
      >
        <a :href="item.address" class="d-flex ai-center" target="_blank">
          <div class="icon">{{ item.name.slice(0, 1) }}</div>
          <span class="text-dark-2">{{ item.name }}</span>
        </a>
        <i class="delete" @click="removeNav(index)">×</i>
      </li>
    </ul>
    <div class="control-wrap p-15 d-flex ai-center jc-center mt-20">
      <input
        ref="navName"
        type="text"
        v-model="model.name"
        placeholder="导航名称"
        @keypress.enter="addItem"
        class="pl-10 mr-20"
      />
      <input
        ref="navAddress"
        type="text"
        v-model="model.address"
        placeholder="请输入链接地址"
        @keypress.enter="addNav"
        class="pl-10"
      />
      <button class="px-15 mx-20 m-c-p" @click="addNav">新增</button>
      <span class="text-border-grey"
        >注意：为确保云同步，使用该功能必须登录！</span
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      model: {
        name: "",
        address: "",
      },
      navList: [],
    };
  },
  methods: {
    async removeNav(index) {
      this.navList.splice(index, 1);
      const result = await this.$axios.post("/navlist", this.navList);
      console.log(result.data.navList);
      this.navList = result.data.navList;
    },
    addNav() {
      const addNavi = async (model = this.model) => {
        this.navList.push(model);
        const result = await this.$axios.post("/navlist", this.navList);
        if (result.data) {
          this.navList = result.data.navList;
        }
        (this.model.name = ""), (this.model.address = "");
      };
      if (this.model.name && this.model.address) {
        if (
          this.model.address.includes("https://") ||
          this.model.address.includes("http://")
        ) {
          addNavi();
        } else {
          const obj = {
            name: this.model.name,
            address: "http://www." + this.model.address,
          };
          addNavi(obj);
        }
      }
    },
    async getNavList() {
      const result = await this.$axios.get("/navlist");
      this.navList = result.data.navList;
    },
  },
  mounted() {
    localStorage.token && this.getNavList();
    !localStorage.token && (this.$refs.navName.disabled = "disabled");
    !localStorage.token && (this.$refs.navAddress.disabled = "disabled");
  },
  computed: {
    loginState() {
      return this.$store.state.LoginState;
    },
  },
  watch: {
    loginState(value) {
      if (!value) {
        this.navList = [];
        !localStorage.token && (this.$refs.navName.disabled = "disabled");
        !localStorage.token && (this.$refs.navAddress.disabled = "disabled");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  margin: 1rem 3rem;
  padding: 0;
  li {
    list-style-type: none;
    &.a-hover:hover{
      font-weight: bold;
    }
    .icon {
      color: white;
      font-size: 1.8rem;
      text-align: center;
      line-height: 3rem;
      border-radius: 5px;
      height: 3rem;
      width: 3rem;
      background: #1e8870;
      margin-right: 0.8rem;
    }
    .delete {
      color: #ccc;
      font-style: normal;
      background: #eee;
      width: 1.4rem;
      height: 1.4rem;
      text-align: center;
      line-height: 1rem;
      border-radius: 0.6rem;
      margin-left: 1rem;
      margin-top: 0.4rem;
      visibility: hidden;
      cursor: pointer;
    }
  }
  li:hover .delete {
    visibility: inherit;
  }
}
.control-wrap {
  input {
    height: 3rem;
    width: 60rem;
  }
  input:first-child {
    width: 10rem;
  }
  button {
    height: 3rem;
    background: none;
    border: 1px solid #ccc;
    cursor: pointer;
  }
}
</style>